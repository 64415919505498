import React from "react"
import "./detailBlog.css"
import Back from "../common/back/Back"
import AwrapperBackup from "./AwrapperBackup"

const BackupStory = () => {
  return (
    <>
      <Back title='Success Story' />
        <AwrapperBackup/>
    </>
  )
}

export default BackupStory
