import React from "react"


const Hparagraf = (section) => {
  return (
    <div className="break paragraf">
     <p>{section.konten}</p>
    </div>
  )
}

export default Hparagraf
