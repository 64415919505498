import React from "react"


const Hsection = (section) => {
  return (
    <div className="break">
     <h2>{section.judul}</h2>
    </div>
  )
}

export default Hsection
