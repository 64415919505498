import React from "react"
import "./detailBlog.css"
import Back from "../common/back/Back"
import AwrapperCoal from "./AwrapperCoal"

const CoalStory = () => {
  return (
    <>
      <Back title='Success Story' />
        <AwrapperCoal/>
    </>
  )
}

export default CoalStory
