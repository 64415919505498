import React from "react"
import "./detailBlog.css"
import Back from "../common/back/Back"
import WrapBtps from "./wrapBtps"

const BTPNSukses = () => {
  return (
    <>
      <Back title='Success Story' />
        <WrapBtps/>
    </>
  )
}

export default BTPNSukses
