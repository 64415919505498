import React from "react"
import "./detailBlog.css"
import Back from "../common/back/Back"
import Awrapper from "./Awrapper"

const SuksesStory = () => {
  return (
    <>
      <Back title='Success Story' />
        <Awrapper/>
    </>
  )
}

export default SuksesStory
